import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatIconModule } from '@angular/material/icon';
import { LogoModule } from '../layout/common/logo/logo.module';
import { LogoIconModule } from '../layout/common/logo-icon/logo-icon.module';
import { LoadingComponent } from './components/loading/loading.component';
import { ExpansiveAlertComponent } from './components/expansive-alert/expansive-alert.component';
import { CpfCnpjPipe } from './pipes/cpfCnpj.pipe';
import { PhoneBrazilPipe } from './pipes/phoneBrazil.pipe';
import { HelpersService } from './helpers.service';
import { ApplicationLabelComponent } from './application-label/application-label.component';

@NgModule({
    declarations: [
        LoadingComponent,
        CpfCnpjPipe,
        PhoneBrazilPipe,
        ExpansiveAlertComponent,
        ApplicationLabelComponent,
    ],
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        LogoModule,
        LogoIconModule,
        MatProgressSpinnerModule,
        MatExpansionModule,
        MatIconModule,
    ],
    exports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,

        // components
        LogoModule,
        LogoIconModule,
        LoadingComponent,
        CpfCnpjPipe,
        PhoneBrazilPipe,
        ExpansiveAlertComponent,
        ApplicationLabelComponent,
    ],
    providers: [HelpersService, CpfCnpjPipe, PhoneBrazilPipe],
})
export class SharedModule {}
